import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'
import '../App.css'
import About from '../components/About'
import Particles from "react-tsparticles";


const PageHeader = () => (
  <div class="bg">
    
    <div class="particles-bg">
    <Particles 
                    height="100vh"
                    options={{
                    background: {
                        color: {
                        value: "#000000",
                        },
                    },
                    fpsLimit: 60,
                    particles: {
                        color: {
                        value: "#ffffff",
                        },
                        links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.1,
                        width: 1,
                        },
                        collisions: {
                        enable: true,
                        },
                        move: {
                        direction: "none",
                        enable: true,
                        outMode: "out",
                        random: false,
                        speed: 2,
                        straight: false,
                        },
                        number: {
                        density: {
                            enable: true,
                            value_area: 1000,
                        },
                        value: 80,
                        },
                        opacity: {
                        value: 0.3,
                        },
                        shape: {
                        type: "circle",
                        },
                        size: {
                        random: true,
                        value: 3,
                        },
                    },
                    detectRetina: true,
                    }}
                />
    </div>

    <div data-aos="fade-left" class="outline">
      <Segment basic padded="very">
        <h1>Osama Naim</h1>
      </Segment>
    </div>
    
    <div data-aos="fade-right" class="outline">
      <Segment basic padded="very" textAlign="right">
        <h2>Data Engineer</h2>
      </Segment>
      {/* <div data-aos="fade-left">
        <About />
      </div> */}
    </div>

    <div data-aos='fade-right' class="outline">
      <Icon name="angle double down" size="big"/>
    </div>
  </div>
)

export default PageHeader