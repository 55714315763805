import React, { Component } from 'react'
import { Card, Segment, Icon } from 'semantic-ui-react'
import '../App.css'

export class About extends Component {
    render() {
        return (
            <div class="ui container">
                <Card fluid>
                    <Segment basic color="grey">
                        <h3>Skills</h3>
                        <Icon name="js" size="big"/>
                        <Icon name="python" size="big"/>
                        <Icon className="logo192.png" />
                    </Segment>
                </Card>
            </div>
        )
    }
}

export default About