import React, { Component } from 'react'
import { Container, Segment } from 'semantic-ui-react'

export class Certs extends Component {
    render() {
        return (
            <div class="container ui">
                <Segment>
                    <h3>Certifications</h3>
                    <Segment.Group>
                        <Segment>
                            <p>AWS Certified Solutions Architect - Associate</p>
                        </Segment>
                        {/* </div> */}
                        <Segment>
                            <p>Secure Code Warrior</p>
                        </Segment>
                    </Segment.Group>
                </Segment>
            </div>
        )
    }
}

export default Certs
