import React from 'react';
import './App.css';
import PageHeader from './components/PageHeader'
import 'semantic-ui-css/semantic.min.css'
import About from './components/About'
import Projects from './components/Projects'
import Experience from './components/Experience'
import { Divider } from 'semantic-ui-react'
import Certs from './components/Certs'

import Aos from "aos";
import "aos/dist/aos.css";


function App() {
  
  Aos.init({
    duration: 500
  })

  return (
    <div>
      <PageHeader />
      
      {/* <div data-aos="fade-left">
        <About />
      </div> */}


      {/* <Divider hidden className="ui container"/> */}
      
      
      <div class="info">

        <div data-aos="fade-right">
          <Experience />
        </div>  
          
        <Divider hidden className="ui container"/>
          
        <div data-aos="fade-left">
          <Projects />
        </div>  
          
        <Divider hidden className="ui container"/>

        <div data-aos="fade-right">
          <Certs />
        </div>  
      </div>

    </div>
  );
}

export default App;