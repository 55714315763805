import React, { Component } from 'react'
import { Container, Segment, Grid, Divider, Item, Header} from 'semantic-ui-react'
// import "aos/dist/aos.css";


export class Experience extends Component {
    render() {
        return (
            <div class="container ui">
                <Segment basic inverted>
                    <Header as="h2">Experience</Header>
                    {/* <h3>Experience</h3> */}
                    <Divider />
                    {/* <div data-aos="fade-up"> */}

                    <Item.Group divided>
                        <Item>
                        {/* <Item.Image size='tiny' src='/images/wireframe/image.png' /> */}
                        <Item.Content verticalAlign='middle'>Content A</Item.Content>
                        </Item>

                        <Item>
                        {/* <Item.Image size='tiny' src='/images/wireframe/image.png' /> */}
                        <Item.Content verticalAlign='middle'>Content B</Item.Content>
                        </Item>

                        <Item>
                        {/* <Item.Image size='tiny' src='/images/wireframe/image.png' /> */}
                        <Item.Content content='Content C' verticalAlign='middle' />
                        </Item>
                    </Item.Group>

                </Segment>
            </div>
        )
    }
}

export default Experience
