import React, { Component } from 'react'
import { Segment, Grid, Divider } from 'semantic-ui-react'


export class Projects extends Component {
    render() {
        return (
            <div class="container ui">
                <Segment basic inverted color="grey">
                    <h3>Personal Projects</h3>
                    <Segment.Group basic>
                        <Segment basic>
                            <p>Cloud Resume Challenge</p>
                        </Segment>
                    </Segment.Group>
                </Segment>
            </div>
        )
    }
}

export default Projects
